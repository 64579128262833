import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import classnames from 'classnames';

import BackgroundImage from 'gatsby-background-image';

const SectionHero = ({fileName, className, children}) => {
  const classes = classnames('section__hero', className);
  const data = useStaticQuery(
    graphql`
      query {
        placeholderImage: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 2100, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );
  const image = data.placeholderImage.edges.find(({node}) => node.relativePath === fileName).node;
  if (!image) {
    return null;
  }
  return (
    <BackgroundImage Tag="section" className={classes} fluid={image.childImageSharp.fluid}>
      {children}
    </BackgroundImage>
  );
};

SectionHero.propTypes = {
  fileName: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SectionHero;
