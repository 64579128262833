import React from 'react';
import {PropTypes} from 'prop-types';

/**
 * A Facebook Pixel that is fired when the page is loaded. By default, the pixel
 * will fire a `PageView` event on load. You may disable the with the
 * `disablePageView` argument. Additionally, you may pass an arbitrary list of
 * even names to fire in the `events` argument.
 */
const FacebookPixel = ({id, events = null, disablePageView = false}) => {
  // Admittedly, this is a weird approach—especially the next two lines. This
  // approach is required because `gatsby develop` and `gatsby build` use two
  // different build processes. What we were seeing is that the code would work
  // fine during `gatsby develop`, but running `NODE_ENV=production gatsby
  // build` was giving a "window not found" error. In fact, you can't even
  // import the code from 'react-facebook-pixel', because it assumes the
  // `window` object exists. Thus, we have to do this workaround to only load it
  // only the client side (not during SSR) via `gatsby build`.

  if (typeof window !== `undefined`) {
    // eslint-disable-next-line global-require
    const ReactPixel = require('react-facebook-pixel').default;
    ReactPixel.init(id);

    if (!disablePageView) ReactPixel.pageView();

    if (events) {
      events.forEach(event => {
        ReactPixel.track(event);
      });
    }
  }

  return <></>;
};

FacebookPixel.propTypes = {
  id: PropTypes.number,
  events: PropTypes.array,
  disablePageView: PropTypes.bool,
};

export default FacebookPixel;
