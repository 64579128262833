import React from 'react';
import {PropTypes} from 'prop-types';

const DGLVPixel = ({id}) => {
  // Admittedly, this is a weird approach—especially the next two lines. This
  // approach is required because `gatsby develop` and `gatsby build` use two
  // different build processes. What we were seeing is that the code would work
  // fine during `gatsby develop`, but running `NODE_ENV=production gatsby
  // build` was giving a "window not found" error. In fact, you can't even
  // import the code from 'react-facebook-pixel', because it assumes the
  // `window` object exists. Thus, we have to do this workaround to only load it
  // only the client side (not during SSR) via `gatsby build`.

  if (typeof window !== `undefined`) {
    return (
      <img src={`https://track.dglv.com/?mode=retarget&amp;o=${id}`} width="0" height="1" alt="" />
    );
  }

  return <></>;
};

DGLVPixel.propTypes = {
  id: PropTypes.string,
};

export default DGLVPixel;
