import React from 'react';
import {PropTypes} from 'prop-types';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function openLink(id, href) {
  return () => {
    // Fire pixel
    const pixel = new Image();
    pixel.src = `https://track.dglv.com/?mode=pixel&amp;o=${id}`;
    pixel.width = 0;
    pixel.height = 1;
    pixel.alt = '';
    document.body.appendChild(pixel);

    // Open href
    const a = document.createElement('a');
    a.href = href;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
  };
}

const DGLVButton = ({id, href, className, buttonText, faIcon = null, faIconStyle = null}) => (
  <Button
    type="button"
    onClick={openLink(id, href)}
    onKeyPress={openLink(href)}
    className={className}
  >
    {buttonText}
    {faIcon && <FontAwesomeIcon icon={faIcon} style={faIconStyle} />}
  </Button>
);

DGLVButton.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  faIcon: PropTypes.any,
  faIconStyle: PropTypes.any,
};

export default DGLVButton;
